import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProjectList from '../components/Posts/ProjectList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';
import { FooterTopBlock, IntroContentBlock, Header } from '../components/Acf';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, wordpressPost: projectsPage = [], categories, categoryFilter, site, wordpressWpSettings } = data
    const { edges: posts, totalCount } = postsOnPage
    const { yoast, introBlock = null } = projectsPage ? projectsPage : { yoast: {}, introBlock: null }
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug } = pageContext
    const title = `${category} Projects`

    return (
      <Layout className={`page-wrap`}>
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast} />
        <Header title={title} location={location} />
        {/* {introBlock && <IntroContentBlock {...introBlock} />} */}
        <ProjectList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix="projects"
          pathSuffix={`category/${slug}/`}
        />
        <FooterTopBlock useGlobal />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectCategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    categoryFilter: allWordpressWpProjects {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    wordpressPost: wordpressPage(slug: {eq: "projects"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      introBlock: childWordPressAcfIntroContentBlock {
        title
        titleSeparateLine
        subtitle
        content
        actions {
          link {
            title
            url
            target
          }
        }
      }
    }
    postsOnPage: allWordpressWpProjects(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "gatsby-demo" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
